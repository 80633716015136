<template>
  <div class="projects">
    <projects-missing-data
      v-if="isDataMissing && !isLoading"
      class="projects__missing-data d-flex flex-column justify-center"
      :is-archive="isArchive"
    />

    <template v-else>
      <search-bar
        :key="$route.name"
        v-model="query"
        class="mb-6"
        :placeholder="$t('label.projects_search')"
        single-line
        full-width
        hide-details
      />

      <div class="projects__list">
        <list-loading v-if="isLoading" />

        <projects-list v-else :open-all="isSearch" :projects="projects" :access-to-rooms="accessToRooms" />
      </div>

      <base-pagination
        v-if="pageCount && !isLoading"
        v-model="pageModel"
        color="secondary"
        class="mt-10 text--primary"
        :length="pageCount"
      />
    </template>
  </div>
</template>

<script>
// Constants
import { PROJECTS, PROJECTS_ARCHIVE } from '@/constants/routes';
import { PROJECTS_LIST } from '@/constants/analyticsActions';

// Utils
import { debounce } from '@/utils/delay';

// Services
import realtyService from '@/services/realty';
import analyticsService from '@/services/analytics';

// Components
import SearchBar from '@/components/SearchBar.vue';
import ListLoading from '@/components/ListLoading.vue';
import BasePagination from '@/components/BasePagination.vue';
import ProjectsMissingData from '@/components/Projects/MissingData.vue';
import ProjectsList from '@/components/Projects/List.vue';

const ENTITY_TYPES = {
  PROJECT: 'project',
  BUILDING: 'building',
  UNIT: 'unit',
  NO_UNITS: 'noUnits',
  NO_BUILDINGS: 'noBuildings',
};

export default {
  name: 'Projects',

  components: { SearchBar, ListLoading, BasePagination, ProjectsMissingData, ProjectsList },

  props: {
    isArchive: { type: Boolean, required: true },
    sendRequest: { type: Boolean, required: true },
    accessToRooms: { type: Boolean, required: true },
  },

  data() {
    return {
      query: '',
      projects: [],
      isLoading: true,
      page: +this.$route.query.page || 1,
      pageCount: 1,
      isDataMissing: false,
    };
  },

  computed: {
    isSearch() {
      return !!this.query;
    },

    pageModel: {
      get() {
        return this.page;
      },

      set(val) {
        this.$router.push({
          name: this.isArchive ? PROJECTS_ARCHIVE : PROJECTS,
          query: { page: val },
        });
      },
    },

    offset() {
      return (this.page - 1) * this.$options.projectsLimit;
    },
  },

  watch: {
    sendRequest(value) {
      if (value) {
        this.fetchProjects();
        this.$emit('update:send-request', false);
      }
    },

    query(newVal, oldVal) {
      if (newVal === oldVal) return;

      this.isLoading = true;
      this.$options.debounceSearch();

      this.pageModel = 1;
    },

    // eslint-disable-next-line
    '$route.query.page': function(newVal, oldVal) {
      if (Number(newVal) === Number(oldVal)) return;

      this.page = +newVal || 1;
      this.fetchProjects();
    },

    // eslint-disable-next-line
    '$route.name': function(newVal, oldVal) {
      if (newVal === oldVal) return;
      this.isLoading = true;
      if (this.isDataMissing) {
        this.isDataMissing = false;
      }
      this.fetchProjects()
        .then(() => {
          this.isDataMissing = this.projects.length === 0 && !this.query;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    isDataMissing: {
      handler(newData) {
        this.$emit('update:is-data-missing', newData);
      },
      immediate: true,
    },
  },

  mounted() {
    analyticsService.track(PROJECTS_LIST);
    this.fetchProjects().then(() => {
      if (this.projects.length === 0 && !this.query) {
        this.isDataMissing = true;
      }
    });
  },

  beforeMount() {
    this.$options.debounceSearch = debounce(() => {
      this.search();
    }, 600);
  },

  methods: {
    fetchProjects() {
      this.isLoading = true;

      return realtyService
        .getProjects({
          limit: this.$options.projectsLimit,
          offset: this.offset,
          isArchived: this.isArchive,
          search: this.query,
        })
        .then(projectsResponse => {
          this.pageCount = Math.ceil(projectsResponse.count / this.$options.projectsLimit);

          const projects = projectsResponse.results;
          this.projects = projects.map(project => ({ ...project, isLoading: false, type: ENTITY_TYPES.PROJECT }));
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    search() {
      this.fetchProjects();
    },

    importProject(project) {
      realtyService.importProject(project);
    },
  },

  projectsLimit: 7,
  debounceSearch: null,
};
</script>

<style lang="scss">
.projects {
  height: 100%;

  &__missing-data {
    height: 100%;
  }

  &__list {
    min-height: 455px;
  }

  .v-treeview-node__toggle {
    display: none;
  }

  .v-treeview-node__content {
    margin-left: 0 !important;

    .v-btn {
      flex-shrink: 0 !important;
    }
  }

  .v-treeview-node__root {
    padding: 0 !important;
    min-height: 0;
    background: $--project-expansion-panel-background;
    border-bottom: 1px solid rgba($--blue-color-20, 0.15);
  }

  .v-treeview-node:first-child .v-treeview-node__root {
    border-radius: 4px 4px 0 0;
  }

  .v-treeview-node:last-child .v-treeview-node__root {
    border-radius: 0 0 4px 4px;
  }

  &__unit,
  &__building,
  &__project,
  &__room,
  &__no-units,
  &__no-rooms,
  &__no-buildings {
    cursor: default;
    padding: 16px 24px !important;

    @media (max-width: map.get($--screens, 'sm')) {
      padding: 18px 8px !important;
    }

    &.pointer {
      cursor: pointer;
    }
  }

  &__building,
  &__no-buildings {
    background: $--blue-color-60 !important;
    padding-left: 56px !important;

    @media (max-width: map.get($--screens, 'sm')) {
      padding-left: 24px !important;
    }
  }

  &__unit,
  &__no-units {
    background: $--blue-color-70 !important;
    padding-left: 80px !important;

    @media (max-width: map.get($--screens, 'sm')) {
      padding-left: 32px !important;
    }
  }

  &__room,
  &__no-rooms {
    background: #ffffff;
    padding-left: 109px !important;

    @media (max-width: map.get($--screens, 'sm')) {
      padding-left: 44px !important;
    }
  }

  &__no-units,
  &__no-buildings,
  &__no-rooms {
    justify-content: center;
    padding-left: 24px !important;

    @media (max-width: map.get($--screens, 'sm')) {
      padding-left: 8px !important;
    }
  }

  .v-treeview-node__level {
    display: none;
  }

  &__icon-stub {
    width: 24px;
  }

  &__empty-text {
    opacity: 0.5;
    color: $--primary-color;
  }

  &__more-info {
    color: $--blue-color-0 !important;
    text-decoration: underline;
  }

  &__item-name {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    color: #040404 !important;

    &:not(.projects__empty-text):hover {
      text-decoration: underline;
    }

    &--archive {
      opacity: 0.5;
    }

    @media (max-width: map.get($--screens, 'sm')) {
      font-weight: bold;
    }
  }

  .v-treeview-node__root::before {
    background: none !important;
  }

  .v-treeview-node--active.primary--text {
    color: inherit !important;
  }
}
</style>
